<template>
    <div class="StorePromotion">
        <div class="StorePromotionHeader">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>营销工具</el-breadcrumb-item>
                <el-breadcrumb-item>店铺促销</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="headerTitle">
                <p>店铺促销</p>
<!--                <el-button class="btn" @click="deleSelectPromotion">结束推广</el-button>-->
            </div>
        </div>
        <div class="StorePromotionMiddle">
            <div class="StorePromotionMiddleHeader">
                <div class="newActivities">
                    <span>自定义设置</span>
                    <div class="activitiesBox"  @click="fullReduction('1')"><span>创建新活动</span></div>
                </div>
                <div class="newActivities">
                    <span>常用活动类型</span>
                    <div class="activitiesBox" @click="fullReduction('2')"><span>创建满减促销</span></div>
                </div>
                <div class="newActivities">
                    <div class="activitiesBox"  @click="fullReduction('3')"><span>创建满元赠促销</span></div>
                </div>
            </div>
            <div class="StorePromotionMiddleTable">
                <el-table :data="tableData" style="width: 100%;flex: 1;margin-top: 20px;border: 1px solid #DCDCDC;height: calc(100vh - 350px);overflow-y: auto" :cell-style="{height: '70px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}" :header-cell-style="{fontWeight: '500', color: '#666666', background: '#F5F5F5',height: '46px'}">
                    <el-table-column label="促销名称" prop="name"></el-table-column>
                    <el-table-column label="促销信息" prop="activity_detail"></el-table-column>
                    <el-table-column label="促销方式" prop="type" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.type}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" style="color: #1E63F1" @click="editData(scope.row.activity_data.type,scope.row.id)">修改</el-button>
                            <el-button type="text" style="color: #FD4446" @click="deleData(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: "StorePromotion",
        data(){
            return {
                tableData:[],
                dataId:null,
            }
        },
        created() {
            this.getShowStuMarket();
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            }
        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getShowStuMarket(){
                let param = {
                    type:'1,2'
                }
                this.$http.axiosGetBy(this.$api.showStuMarket, param, res=>{
                    if(res.code === 200){
                        let menus = this.menus;
                        if (menus) {
                            let currentStatus = menus[1].children[1].status;
                            if (Number(currentStatus) === 0) {
                                this.operation_updateStatus(1, 1, 1);
                            }
                        }
                        if(res.data.data.length){
                            this.tableData = res.data.data[0].stu_market_data;
                            this.dataId = res.data.data[0].id
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            editData(type,id){
                console.log(77,type)
                if(type == 1){
                    this.$router.push({
                        path:'/student/operationpromotion/storepromotionone',
                        query:{
                            classify:2,
                            editId:this.dataId
                        }
                    })
                } else if(type == 2){
                    this.$router.push({
                        path:'/student/operationpromotion/storepromotionone',
                        query:{
                            classify:3,
                            editId:this.dataId
                        }
                    })
                }
            },
            deleData(id){
                this.$confirm('您确定要删除该促销？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning'
                }).then(()=>{
                    this.$http.axiosGetBy(this.$api.delStudentMarket, {id:id}, res=>{
                        if(res.code === 200){
                            this.$message({
                                type: 'success',
                                message: '删除成功!',
                                onClose:()=>{
                                    this.getShowStuMarket()
                                }
                            });
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            deleSelectPromotion(){
                this.$confirm('确定要结束推广么？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosGet(this.$api.endMarketStu, res=>{
                        if(res.code === 200){
                            this.$message({
                                type: 'success',
                                message: '结束成功!'
                            });
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消结束'
                    });
                });
            },
            fullReduction(num){
                console.log(98,num);
                this.$router.push({
                    path:'/student/operationpromotion/storepromotionadd',
                    query:{
                        classify:num
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-breadcrumb .el-breadcrumb__item .el-breadcrumb__inner{
        color: #9A9A9A;
        font-size: 14px;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.el-button--primary{
        background-color: #FD4446!important;
    }
    .StorePromotion{
        .StorePromotionHeader{
            /*display: flex;*/
            /*justify-content: space-around;*/
            .headerTitle{
                display: flex;
                justify-content: space-between;
                p{
                    color: #333333;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1;
                    margin: 20px 0 0 0;
                }
                .btn{
                    width: 100px;
                    height: 36px;
                    border-color: #FD4446;
                    line-height: 0;
                    background: #F7F9FB;
                    color: #FD4446;
                }
            }
        }
        .StorePromotionMiddle{
            padding: 20px;
            margin-top: 18px;
            background: #ffffff;
            .StorePromotionMiddleHeader{
                display: flex;
                .newActivities{
                    display: flex;
                    flex-direction: column;
                    span{
                        color: #343434;
                        font-size: 14px;
                        line-height: 1;
                    }
                    .activitiesBox{
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 10px;
                        width: 136px;
                        height: 72px;
                        border-radius: 4px;
                        text-align: center;
                        span{
                            font-size: 12px;
                            color: #FFFFFF;
                            margin-top: 28px;
                        }
                    }
                }
                .newActivities:nth-child(1){
                    .activitiesBox{
                        background: url("./../../../../assets/images/newActivities.png") no-repeat;
                    }
                }
                .newActivities:nth-child(2){
                    margin-left: 60px;
                    .activitiesBox{
                        background: url("./../../../../assets/images/newActivities2.png") no-repeat;
                    }
                }
                .newActivities:nth-child(3){
                    margin-top: 14px;
                    margin-left: 20px;
                    .activitiesBox{
                        background: url("./../../../../assets/images/newActivities3.png") no-repeat;
                    }
                }
            }
        }
    }
</style>